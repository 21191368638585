@font-face {
  font-family: 'Helvetica';
  src: url('../src/assets/fonts/Helvetica.ttf') format('ttf');
  src: url('../src/assets/fonts/Helvetica-Bold.ttf') format('ttf');
  src: url('../src/assets/fonts/Helvetica-BoldOblique.ttf') format('ttf');
  src: url('../src/assets/fonts/helvetica-compressed.otf') format('ttf');
  src: url('../src/assets/fonts/helvetica-light.ttf') format('ttf');
  src: url('../src/assets/fonts/Helvetica-Oblique.ttf') format('ttf');
  src: url('../src/assets/fonts/helvetica-rounded-bold.otf') format('ttf');
}

body {
  margin: 0;
  font-family: 'Helvetica', Tahoma, sans-serif;
  @media (min-width: 767.98px) {
    font-size: larger;
  }
  @media (min-width: 1023.98px) {
    font-size: x-large;
  }
}

.wrapper {
  max-width: 1980px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10%;
}

a {
  color: #18515d;
  text-decoration: none;
  margin-top: 20px;
}

.content {
  width: 100%;
  display: flex;    
  justify-content: space-evenly;
  flex-wrap: wrap;
  img {
    width: 90%;
    margin: 1vh 0;
    @media (min-width: 575.98px) {
      width: 45%;
    }
    @media (min-width: 991.98px) {
     width: 31%;
    }
  }
 }
.title {
  color: #18515d;
  text-align: center;
  margin-bottom: 5%;
}

.header {
  position: absolute;
  left: 5%;
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // padding: 30px 50px;
  @media (min-width: 1023.98px) {
    top: 5vh;
  }
  .logo {
    width: 100px;
    @media (min-width: 1023.98px) {
      width: 150px;
    }
  }
  .menu-icon {
    width: 30px;
    height: 30px;
    @media (min-width: 1023.98px) {
      width: 50px;
      height: 50px;
    }
  }
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  background: #e8d2ae;
  @media (min-width: 991.98px) {
    width: 45%;
    height: 100vh;
    right: 0;
    left: auto;
  }
}

.close-btn {
  position: fixed;
  z-index: 100;
  top: 5vh;
  right: 5%;
  background: transparent;
  border: none;
  img {
    width: 32px;
  }
}
.menu-modal {
  display: flex;
  height: 100vh;
  align-content: center;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: auto;
  left: 30%;
  overflow: hidden;
  z-index: 100;
  @media (min-width: 499.98px) {
    left: 35%;
  }
  @media (min-width: 991.98px) {
    left: 70%;
    // top: 15vh;
  }

  .menu-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    line-height: 2.5;
    font-weight: 700;
    @media (min-width: 1023.98px) {
      font-size: x-large;
      align-items: start;
    }
    a {
      text-transform: uppercase;
      color: #17505c;
      text-decoration: none;
      &:hover {
        color: #f16923;
      }
      &:active {
        color: #796465;
      }
    }
  }
}

.home {
  display: flex;
  justify-content: center;
  max-width: 1980px;
  margin: 0 auto;
  height: 100vh;
  background-color: #18515d;
  .content {
    width: 70%;
    display: flex;
    flex-direction: column;
    // margin-top: 40%;
    @media (min-width: 1023.98px) {
      width: 50%;
      // margin-top: 10%;
    }
  }
  .date {
    display: flex;
    align-self: flex-end;
    justify-content: center;
    .vl {
      border-left: 4px solid #e8d2ae;
      height: 125px;
    }
    p {
      font-size: 20px;
      color: #e8d2ae;
      line-height: 0.5;
    }
    .year {
      align-self: center;
      margin-left: 10px;
    }
    .month {
      align-self: center;
      margin-right: 10px;
    }
    span {
      color: #f16923;
    }
  }

  img {
    width: 100%;
    align-self: center;
    margin: 5vh 0;
    @media (min-width: 1023.98px) {
      width: 50%;
      align-self: flex-end;
    }
  }
  button {
    width: 140px;
    height: 50px;
    background-color: transparent;
    border: 2px solid #f16923;
    align-self: flex-end;
    a {
      color: #e8d2ae;
      text-decoration: none;
    }
  }
}

.main {
  img {
    margin-top: 90px;  
    width: 100%;
    @media (min-width: 1023.98px) {
     margin-top: 180px;
    }
  }
}

.about {
  width: 80%;
  align-self: center;
  
  .images {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .hidden {
      display: flex;
      justify-content: end;
      @media (min-width: 1023.98px) {
        width: 20%;
      }
      .arrow {
        width: 80px;
        height: 80px;
        align-self: flex-end;
        @media (min-width: 767.98px) {
          width: 120px;
          height: 120px;
        }
      }
    }
    .artist {
      width: 50%;
    }
  }
  .text {
    color: #010101;
    text-align: justify;
    @media (min-width: 1023.98px) {
      width: 80%;
      margin-left: 20%;
    }
  }
  .text-spec {
    font-weight: 300;
    @media (min-width: 1023.98px) {
      margin-left: 20%;
    }
  }
}


.event {
  margin-top: 10vh;
    // .eventland {
    //   width: 300px;
    //   height: 300px;
    //   background-repeat: no-repeat;
    //   background-size: contain;
    //   margin: 1%;
    //   @media (min-width: 399.98px) {
    //     width: 400px;
    //     height: 400px;
    //   }
    //   @media (min-width: 499.98px) {
    //     width: 500px;
    //     height: 500px;
    //   }
    //   @media (min-width: 599.98px) {
    //     width: 600px;
    //     height: 600px;
    //   }
    //   @media (min-width: 699.98px) {
    //     width: 700px;
    //     height: 700px;
    //   }
    //   @media (min-width: 767.98px) {
    //     width: 320px;
    //     height: 320px;
    //   }
    //   @media (min-width: 1023.98px) {
    //     width: 420px;
    //     height: 420px;
    //   }
    //   @media (min-width: 1659.98px) {
    //     width: 520px;
    //     height: 520px;
    //   }
    // }
  }



.behind {
  margin-top: 10vh;
   .content {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;

    // .behindland {
    //   width: 300px;
    //   background-repeat: no-repeat;
    //   background-size: contain;
    //   margin: 1%;
    //   @media (min-width: 399.98px) {
    //     width: 400px;
    //     height: 400px;
    //   }
    //   @media (min-width: 499.98px) {
    //     width: 500px;
    //     height: 500px;
    //   }
    //   @media (min-width: 599.98px) {
    //     width: 600px;
    //     height: 600px;
    //   }
    //   @media (min-width: 699.98px) {
    //     width: 700px;
    //     height: 700px;
    //   }
    //   @media (min-width: 767.98px) {
    //     width: 320px;
    //     height: 320px;
    //   }
    //   @media (min-width: 1023.98px) {
    //     width: 420px;
    //     height: 420px;
    //   }
    //   @media (min-width: 1659.98px) {
    //     width: 520px;
    //     height: 520px;
    //   }
    // }
  }
}

.archive {  
  margin-top: 10vh;
  .content {
    display: flex;   
     justify-content: space-evenly;
    flex-wrap: wrap;
    // .archiveland {
    //   width: 300px;
    //   height: 300px;
    //   background-repeat: no-repeat;
    //   background-size: contain;
    //   margin: 1%;
    //   @media (min-width: 399.98px) {
    //     width: 400px;
    //     height: 400px;
    //   }
    //   @media (min-width: 499.98px) {
    //     width: 500px;
    //     height: 500px;
    //   }
    //   @media (min-width: 599.98px) {
    //     width: 600px;
    //     height: 600px;
    //   }
    //   @media (min-width: 699.98px) {
    //     width: 700px;
    //     height: 700px;
    //   }
    //   @media (min-width: 767.98px) {
    //     width: 320px;
    //     height: 320px;
    //   }
    //   @media (min-width: 1023.98px) {
    //     width: 420px;
    //     height: 420px;
    //   }
    //   @media (min-width: 1659.98px) {
    //     width: 520px;
    //     height: 520px;
    //   }
    // }
  }
}

.team {
  align-items: inherit;
  margin: 0 auto;
  margin-bottom: 10%;
  // background-image: url('./assets/images/team.png');
  
  // background-size: cover;
  // background-repeat: round;
  //  @media (min-width: 299.98px) {
  //       width: 100%;
  //       height: 350px;
  //     }
  //  @media (min-width: 399.98px) {
  //       width: 100%;
  //       height: 450px;
  //     }
  //     @media (min-width: 499.98px) {
  //       width: 100%;
  //       height: 550px;
  //     }
  //     @media (min-width: 599.98px) {
  //       width: 100%;
  //       height: 650px;
  //     }
  //     @media (min-width: 699.98px) {
  //       width: 100%;
  //       height: 750px;
  //     }
  //     @media (min-width: 767.98px) {
  //       width: 760px;
  //       height:760px;
  //     }
  //     @media (min-width: 1023.98px) {
  //       width: 1000px;
  //       height: 1000px;
  //     }
  //     @media (min-width: 1659.98px) {
  //       width: 1380px;
  //       height: 1380px;
  //     }
  // .title2 {
  //   color: #18515d;
  //   text-align: center;
  // }
  // .title {
  //   margin-top: 60%;
  // }
  .content {
    width: 100%;
    display: flex;    
    justify-content: space-evenly;
    flex-wrap: wrap;
    img {
      width: 90%;
      margin: 1vh 0;
    
    }
   }
}

.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #18515d;
  .title {
    font-size: 20px;
    margin-top: 20%;
    line-height: 0;
    @media (min-width: 1023.98px) {
      font-size: x-large;
      margin-top: 10%;
    }
  }
  p {
    line-height: 0;
 
  }
  .socials {
    margin-top: 10px;
    img {
      height: 20px;
      margin: 0 10px;
      @media (min-width: 1023.98px) {
        height: 40px;
      }
    }
  }
  .arrow {
    width: 80px;
    margin: 10% 10% 0 auto;
    @media (min-width: 767.98px) {
      width: 120px;
    }
  }
}

.map {
  img {
    width: 100%;
  }
}
